import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import coiffures from "../../assets/json/coiffuresJson";
import "moment/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";
import DefaultContent from "./components/default-content";
import SearchResultContent from "./components/search-result-content";
import { DatePicker, Select, Form, Button, Alert } from "antd";
import { useSearchMutation } from "./service/home-api";
import cities from "../../utils/cities.js";
import { BiCurrentLocation } from "react-icons/bi";
import "./styles/home.scss";
import { useDispatch, useSelector } from "react-redux";
import { useUserUpdateMutation } from "../account/profile/service/user-api";
import { setUser } from "../auth/service/auth-slice";
import { useAuthFetchTokenMutation } from "../auth/service/auth-api";
import moment from "moment";

function HomePage() {
  const [search] = useSearchMutation();
  const [citySelected, setCitySelected] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [locationFailed, setLocationFailed] = useState(false);
  const [data, setData] = useState();
  const [userUpdate] = useUserUpdateMutation();
  const [authFetchToken] = useAuthFetchTokenMutation();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleChangeDate = (val) => {
    if (val) {
      setSelectedDate(moment(val?._d).format("YYYY-MM-DD"));
    } else {
      setSelectedDate(null);
    }
  };
  const handleChangeCoiffure = (value) => setSelectedService(value);

  const getUserTimezoneOffsetInHours = () => {
    const offsetInMinutes = moment().utcOffset();
    const offsetInHours = offsetInMinutes / 60;
    const sign = offsetInHours >= 0 ? '+' : '';
    return `${sign}${offsetInHours}`;
  };

  const onFinish = () => {
    setIsLoading(true);
    const timezoneOffset = getUserTimezoneOffsetInHours();
    const formattedDate = selectedDate ? `${selectedDate} ${timezoneOffset}` : undefined;

    search({
      city: citySelected !== "Not in France" ? citySelected : undefined,
      prestation: selectedService ? selectedService : undefined,
      date: formattedDate
    })
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        setData(res);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const onChangeCities = (value) => {
    setCitySelected(value);
  };

  const findMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          setLocationFailed(false);
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          try {
            const response = await fetch(
              `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
            );
            const data = await response.json();
            const cityName =
              data.address.city || data.address.town || data.address.village;
            setCitySelected(
              data.address.country === "France" ? cityName : "Not in France"
            );
            if (
              user &&
              user.ville !== cityName &&
              cityName &&
              data.address.country === "France"
            ) {
              userUpdate({ id: user.id, data: { ville: cityName } })
                .unwrap()
                .then(() => {
                  authFetchToken()
                    .unwrap()
                    .then((res) => dispatch(setUser(res)));
                })
                .catch((error) => console.log("error ===>", error));
            }
          } catch (error) {
            console.log("Error fetching city", error);
          }
        },
        function (error) {
          if (error.code === error.PERMISSION_DENIED) {
            setLocationFailed(true);
          }
        }
      );
    } else {
      setLocationFailed(true);
    }
  };

  const handleAlertClose = () => {
    setLocationFailed(false);
  };

  useEffect(() => {
    findMyLocation();
    // eslint-disable-next-line
  }, [user]);

  return (
    <div className="home animate__animated animate__fadeIn">
      {locationFailed && (
        <Alert
          message="Please enable location access in your browser settings to use this feature."
          type="warning"
          closable
          showIcon
          onClose={handleAlertClose}
        />
      )}
      <div className="search-form">
        <Container>
          <Form
            style={{
              margin: "auto",
              display: "flex",
              justifyContent: "center",
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <div className="wrap-input">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Form.Item
                  name="city"
                  rules={[{ required: true, message: "Please select city!" }]}
                  style={{ marginBottom: "0" }}
                >
                  <Select
                    showSearch
                    placeholder="Ville...."
                    className="form-control"
                    onChange={onChangeCities}
                    value={citySelected}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {cities.map((item, index) => (
                      <Select.Option value={item.name} key={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={findMyLocation}
                >
                  <BiCurrentLocation />
                </div>
              </div>
              <Form.Item
                name="service"
                rules={[{ required: true, message: "Please select service!" }]}
                style={{ marginBottom: "0" }}
              >
                <Select
                  placeholder="Quelle coiffure ?"
                  className="form-control"
                  onChange={handleChangeCoiffure}
                >
                  {coiffures.map((index, item) => (
                    <Select.Option value={index} key={item + item.id}>
                      {index}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="date"
                rules={[{ required: true, message: "Please select date!" }]}
                style={{ marginBottom: "0" }}
              >
                <DatePicker
                  locale={locale}
                  placeholder="Quel jour ?"
                  className="form-control"
                  format={"DD-MM-YYYY"}
                  onChange={handleChangeDate}
                />
              </Form.Item>
            </div>
            <Button htmlType="submit">Recherche</Button>
          </Form>
        </Container>
      </div>
      {isLoading && (
        <div className="spin">
          <Spinner animation="grow" />
        </div>
      )}
      {data ? <SearchResultContent data={data} /> : <DefaultContent />}
    </div>
  );
}
export default HomePage;
