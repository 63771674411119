import React, { useState, useEffect } from "react";
import { Form, Input, Button, Col, Row, Checkbox, Select } from "antd";
import { useSelector } from "react-redux";
import cities from "../../utils/cities";
import { InputErrors, InputRegex } from "../../utils/globalVars";
import { validation } from "../../utils/errorHandler";

function FormCoordonnees({ handleCoordonnee, handleClose, locationData }) {
  const [form] = Form.useForm();
  const [isSameDelivery, setIsSameDelivery] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const onChange = (e) => setIsSameDelivery(e.target.checked);

  const handleSubmit = (value) => {
    handleCoordonnee(value);
    handleClose(false);
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({
      region: locationData?.address?.state || "",
      code_postal: locationData?.address?.postcode || "",
      country: locationData?.address?.country || "",
    });
  }, [locationData, form]);

  return (
    <div>
      <h4 className="modal-title">Tes coordonnées</h4>
      <Form
        onFinish={handleSubmit}
        form={form}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={24}>
          <Col lg={12}>
            <Form.Item
              label="Nom"
              name="firstname"
              initialValue={user.firstname}
              rules={[
                { required: true, message: "Champs requis!" },
                () => ({
                  validator(_, value) {
                    return validation(
                      value,
                      InputRegex.alphabetsCheck,
                      InputErrors.nameError
                    );
                  },
                }),
              ]}
            >
              <Input maxLength={80} />
            </Form.Item>

            <Form.Item
              label="Prenom(s)"
              name="lastname"
              initialValue={user.lastname}
              rules={[
                { required: true, message: "Champs requis!" },
                () => ({
                  validator(_, value) {
                    return validation(
                      value,
                      InputRegex.alphabetsCheck,
                      InputErrors.nameError
                    );
                  },
                }),
              ]}
            >
              <Input maxLength={80} />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              initialValue={user.email}
              rules={[{ required: true, message: "Champs requis!" }]}
            >
              <Input type="email" maxLength={100} />
            </Form.Item>
            <Form.Item
              label="Téléphone"
              name="phone"
              initialValue={user.phone}
              rules={[
                { required: true, message: "Champs requis!" },
                () => ({
                  validator(_, value) {
                    return validation(
                      value,
                      InputRegex.mobilePhoneCheck,
                      InputErrors.mobilePhoneError
                    );
                  },
                }),
              ]}
            >
              <Input maxLength={80} minLength={10} />
            </Form.Item>
            <Form.Item
              label="Adresse de livraison"
              name="delivery_address"
              initialValue={user.adresse}
              rules={[
                { required: true, message: "Champs requis!" },
                () => ({
                  validator(_, value) {
                    if (value.trim().length === 0) {
                      return Promise.reject(
                        new Error(InputErrors.addressError)
                      );
                    }
                    return validation(
                      value,
                      InputRegex.addressCheck,
                      InputErrors.addressError
                    );
                  },
                }),
              ]}
            >
              <Input maxLength={100} />
            </Form.Item>
            {!isSameDelivery && (
              <Form.Item
                label="Adresse de facturation"
                name="billing_address"
                rules={[
                  { required: true, message: "Champs requis!" },
                  () => ({
                    validator(_, value) {
                      if (value.trim().length === 0) {
                        return Promise.reject(
                          new Error(InputErrors.addressError)
                        );
                      }
                      return validation(
                        value,
                        InputRegex.addressCheck,
                        InputErrors.addressError
                      );
                    },
                  }),
                ]}
              >
                <Input maxLength={100} />
              </Form.Item>
            )}
            <Form.Item>
              <Checkbox onChange={onChange}>
                Même adresse que la livraison
              </Checkbox>
            </Form.Item>
          </Col>

          <Col lg={12}>
            <Form.Item
              label="Ville"
              name="city"
              initialValue={user.ville}
              rules={[{ required: true, message: "Champs requis!" }]}
            >
              <Select className="form-control" showSearch>
                {cities.map((item, index) => (
                  <Select.Option value={item.name} key={index + item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Region/Etat/Province"
              name="region"
              rules={[
                { required: false, message: "Champs requis!" },
                () => ({
                  // validator(_, value) {
                  //   if (value.trim().length === 0) {
                  //     return Promise.reject(
                  //       new Error(InputErrors.departmentError)
                  //     );
                  //   }
                  //   return validation(
                  //     value,
                  //     InputRegex.departmentCheck,
                  //     InputErrors.regionError
                  //   );
                  // },
                }),
              ]}
            >
              <Input maxLength={80} />
            </Form.Item>
            <Form.Item
              label="Code Postal/ZIP"
              name="code_postal"
              rules={[
                { required: true, message: "Champs requis!" },
                () => ({
                  validator(_, value) {
                    return validation(
                      value,
                      InputRegex.mobilePhoneCheck,
                      InputErrors.postalCodeError
                    );
                  },
                }),
              ]}
            >
              <Input maxLength={80} />
            </Form.Item>
            <Form.Item
              label="Pays"
              name="country"
              rules={[
                { required: true, message: "Champs requis!" },
                () => ({
                  validator(_, value) {
                    if (value.trim().length === 0) {
                      return Promise.reject(
                        new Error(InputErrors.departmentError)
                      );
                    }
                    return validation(
                      value,
                      InputRegex.departmentCheck,
                      InputErrors.countryError
                    );
                  },
                }),
              ]}
            >
              <Input maxLength={80} />
            </Form.Item>
            <Form.Item>
              <Button className="btn-lg btn-primary" htmlType="submit">
                Valider
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default FormCoordonnees;
